<template>
  <main>
    <popups-notification/>

    <navigation-guest-top-nav/>

    <div class="min-h-screen h-full">
      <slot/>
    </div>

    <navigation-guest-footer/>
  </main>
</template>
