<template>
  <footer class="bg-white">
    <div class="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
      <nav aria-label="Footer" class="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12">
        <div v-for="menuItem in menuItems" class="pb-6">
          <nuxt-link :to="menuItem.href" class="text-sm leading-6 text-gray-600 hover:text-gray-900">
            {{ menuItem.name }}
          </nuxt-link>
        </div>
      </nav>

      <p class="mt-10 text-center text-xs leading-5 text-gray-500">
        &copy; {{ (new Date()).getFullYear() }} Post Polish LLC All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script setup>
const menuItems = [
  {name: 'Privacy Policy', href: '/privacy-policy'},
  {name: 'Terms and Conditions', href: '/terms-and-conditions'},
  {name: 'Return and Refund Policy', href: '/return-and-refund-policy'},
  {name: 'Cookie Policy', href: '/cookie-policy'},
];
</script>
