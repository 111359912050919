<template>
  <nav class="bg-white shadow">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="flex h-16 justify-between">
        <div class="flex">
          <div v-if="!user" class="-ml-2 mr-2 flex items-center md:hidden">
            <button
                class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                @click="showMobileMenu = !showMobileMenu">
              <span class="absolute -inset-0.5"></span>
              <icon v-if="showMobileMenu" name="material-symbols:close-small-rounded"/>
              <icon v-else name="mdi:hamburger"/>
            </button>
          </div>

          <div class="hidden md:flex flex-shrink-0 items-center">
            <img class="h-8 w-auto" src="/logo.png">
          </div>

          <div v-if="!user" class="hidden md:ml-6 md:flex md:space-x-8">
            <nuxt-link
                v-for="menuItem in menuItems"
                :class="{'border-indigo-500 text-gray-900': $route.path === menuItem.href, 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700': $route.path !== menuItem.href}"
                :to="menuItem.href"
                class="inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
            >
              {{ menuItem.name }}
            </nuxt-link>
          </div>
        </div>

        <div class="flex items-center">
          <div class="flex-shrink-0">
            <button v-if="user" class="block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer" tabindex="-1"
                    @click.prevent="logout">
              Sign out
            </button>

            <nuxt-link v-else class="btn-primary-md" to="/register">
              Register
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showMobileMenu" class="md:hidden">
      <div class="space-y-1 pb-3 pt-2">
        <nuxt-link
            v-for="menuItem in menuItems"
            :class="$route.path === menuItem.href ? 'bg-indigo-50 border-indigo-500 text-indigo-700' : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'"
            :to="menuItem.href"
            class="block border-l-4 py-2 pl-3 pr-4 text-base font-medium sm:pl-5 sm:pr-6">
          {{ menuItem.name }}
        </nuxt-link>
      </div>
    </div>
  </nav>
</template>

<script setup>
import {useAuthStore} from "~/stores/auth.js";

const router = useRouter()

const menuItems = ref([
  {name: 'Home', href: '/'},
  {name: 'How it works', href: '/#how-it-works'},
  {name: 'Pricing', href: '/#pricing'},
  {name: 'Login', href: '/login'},
])

const showMobileMenu = ref(false)

const user = computed(() => useAuthStore().user)

function logout() {
  useApi('auth/logout', {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + useCookie('token').value
    },
  }).then(async () => {
    await router.push('/')

    window.location.reload()
  })
}
</script>
